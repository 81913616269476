<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <!-- $store.state.base_url + 'images/CIT-13-Years.jpg' -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <!-- email -->
                <b-form-group label="Super Title" label-for="super_title">
                  <validation-provider
                    #default="{ errors }"
                    name="super_title"
                    rules="required"
                  >
                    <b-form-input
                      id="super_title"
                      v-model="form.super_title"
                      :state="errors.length > 0 ? false : null"
                      name="super_title"
                      placeholder="Enter Your Super Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <!-- email -->
                <b-form-group label="Title" label-for="title">
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="form.title"
                      :state="errors.length > 0 ? false : null"
                      name="title"
                      placeholder="Enter Your Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <!-- email -->
                <b-form-group label="Sub Title" label-for="sub_title">
                  <validation-provider
                    #default="{ errors }"
                    name="sub_title"
                    rules="required"
                  >
                    <b-form-input
                      id="sub_title"
                      v-model="form.sub_title"
                      :state="errors.length > 0 ? false : null"
                      name="sub_title"
                      placeholder="Enter Your Sub Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <!-- email -->
                <b-form-group label="Description" label-for="description">
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="form.description"
                      :state="errors.length > 0 ? false : null"
                      name="description"
                      placeholder="Enter Your Description"
                      rows="4"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-5">
                    <b-form-group label="Button Name 1" label-for="btn_1">
                      <validation-provider
                        #default="{ errors }"
                        name="btn_1"
                        rules="required"
                      >
                        <b-form-input
                          id="btn_1"
                          v-model="form.btn_1"
                          :state="errors.length > 0 ? false : null"
                          name="btn_1"
                          placeholder="Enter Your Button Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-7">
                    <b-form-group label="Button Link 1" label-for="btn_link_1">
                      <validation-provider
                        #default="{ errors }"
                        name="btn_link_1"
                        rules="required"
                      >
                        <b-form-input
                          id="btn_link_1"
                          v-model="form.btn_link_1"
                          :state="errors.length > 0 ? false : null"
                          name="btn_link_1"
                          placeholder="Enter Your Button Link"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <b-form-group label="Button Name 2" label-for="btn_1">
                      <validation-provider
                        #default="{ errors }"
                        name="btn_2"
                        rules="required"
                      >
                        <b-form-input
                          id="btn_2"
                          v-model="form.btn_2"
                          :state="errors.length > 0 ? false : null"
                          name="btn_2"
                          placeholder="Enter Your Button Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-7">
                    <b-form-group label="Button Link 2" label-for="btn_link_2">
                      <validation-provider
                        #default="{ errors }"
                        name="btn_link_2"
                        rules="required"
                      >
                        <b-form-input
                          id="btn_link_2"
                          v-model="form.btn_link_2"
                          :state="errors.length > 0 ? false : null"
                          name="btn_link_2"
                          placeholder="Enter Your Button Link"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <!-- email -->
                <b-form-group label="Footer Text" label-for="footer_text">
                  <validation-provider
                    #default="{ errors }"
                    name="footer_text"
                    rules="required"
                  >
                    <b-form-input
                      id="footer_text"
                      v-model="form.footer_text"
                      :state="errors.length > 0 ? false : null"
                      name="footer_text"
                      placeholder="Enter Your Footer Text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="border rounded p-2 mb-2">
              <h4 class="mb-1">Video Setting</h4>

              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    :src="video_image"
                    height="174"
                    width="310"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-form-group label="Video Image" label-for="video_image">
                    <b-form-file
                      id="video_image"
                      accept=".jpg, .png, .gif"
                      @change="loadImage($event)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                  </b-form-group>
                  <!-- email -->
                  <b-form-group label="Video ID" label-for="video_link">
                    <validation-provider
                      #default="{ errors }"
                      name="video_link"
                      rules="required"
                    >
                      <b-form-input
                        id="video_link"
                        v-model="form.video_link"
                        :state="errors.length > 0 ? false : null"
                        name="video_link"
                        placeholder="Enter Your Video ID"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-media-body>
              </b-media>
            </div>
          </div>
        </div>
        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  data() {
    return {
      form: {
        super_title: "",
        title: "",
        sub_title: "",
        description: "",
        btn_1: "",
        btn_link_1: "",
        btn_2: "",
        btn_link_2: "",
        footer_text: "",
        video_link: "",
        video_image: null,
      },
      video_image: null,
      required,
      email,
    };
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    async getBanner() {
      const banner = await this.callApi("get", "/app/banner/show");
      if (banner.status == 200) {
        this.form.super_title = banner.data.banner.super_title;
        this.form.title = banner.data.banner.title;
        this.form.sub_title = banner.data.banner.sub_title;
        this.form.description = banner.data.banner.description;
        this.form.btn_1 = banner.data.banner.btn_1;
        this.form.btn_link_1 = banner.data.banner.btn_link_1;
        this.form.btn_2 = banner.data.banner.btn_2;
        this.form.btn_link_2 = banner.data.banner.btn_link_2;
        this.form.footer_text = banner.data.banner.footer_text;
        this.form.video_link = banner.data.banner.video_link;
        this.video_image =
          this.$store.state.base_url + banner.data.banner.video_image;
      }
    },

    loadImage(e) {
      this.form.video_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.video_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      const fd = new FormData();
      if (this.form.video_image) {
        fd.append(
          "video_image",
          this.form.video_image,
          this.form.video_image.name
        );
      }
      fd.append("super_title", this.form.super_title);
      fd.append("title", this.form.title);
      fd.append("sub_title", this.form.sub_title);
      fd.append("description", this.form.description);
      fd.append("btn_1", this.form.btn_1);
      fd.append("btn_link_1", this.form.btn_link_1);
      fd.append("btn_2", this.form.btn_2);
      fd.append("btn_link_2", this.form.btn_link_2);
      fd.append("footer_text", this.form.footer_text);
      fd.append("video_link", this.form.video_link);

      axios
        .post("/app/banner/update", fd)
        .then((res) => {
          this.getBanner();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.super_title)
              this.e(e.response.data.errors.super_title[0]);
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.sub_title)
              this.e(e.response.data.errors.sub_title[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.btn_1)
              this.e(e.response.data.errors.btn_1[0]);
            if (e.response.data.errors.btn_link_1)
              this.e(e.response.data.errors.btn_link_1[0]);
            if (e.response.data.errors.btn_2)
              this.e(e.response.data.errors.btn_2[0]);
            if (e.response.data.errors.btn_link_2)
              this.e(e.response.data.errors.btn_link_2[0]);
            if (e.response.data.errors.footer_text)
              this.e(e.response.data.errors.footer_text[0]);
            if (e.response.data.errors.video_link)
              this.e(e.response.data.errors.video_link[0]);
            if (e.response.data.errors.video_image)
              this.e(e.response.data.errors.video_image[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  components: {
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
