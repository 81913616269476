<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <validation-observer ref="loginValidation">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-6">
            <b-form-group
              label="Favorite Course Title"
              label-for="favorite_course_title"
            >
              <validation-provider
                #default="{ errors }"
                name="favorite_course_title"
                rules="required"
              >
                <b-form-input
                  id="favorite_course_title"
                  v-model="form.favorite_course_title"
                  :state="errors.length > 0 ? false : null"
                  name="favorite_course_title"
                  placeholder="Enter Your Favorite Course Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Favorite Course Description"
              label-for="favorite_course_description"
            >
              <validation-provider
                #default="{ errors }"
                name="favorite_course_description"
              >
                <b-form-textarea
                  id="favorite_course_description"
                  v-model="form.favorite_course_description"
                  :state="errors.length > 0 ? false : null"
                  name="favorite_course_description"
                  placeholder="Enter Your Favorite Course Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Comment Section Title"
              label-for="comment_title"
            >
              <validation-provider
                #default="{ errors }"
                name="comment_title"
                rules="required"
              >
                <b-form-input
                  id="comment_title"
                  v-model="form.comment_title"
                  :state="errors.length > 0 ? false : null"
                  name="comment_title"
                  placeholder="Enter Your Comment Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Comment Section Description"
              label-for="comment_description"
            >
              <validation-provider
                #default="{ errors }"
                name="comment_description"
              >
                <b-form-textarea
                  id="comment_description"
                  v-model="form.comment_description"
                  :state="errors.length > 0 ? false : null"
                  name="comment_description"
                  placeholder="Enter Your Comment Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Success Story Title"
              label-for="success_story_title"
            >
              <validation-provider
                #default="{ errors }"
                name="success_story_title"
                rules="required"
              >
                <b-form-input
                  id="success_story_title"
                  v-model="form.success_story_title"
                  :state="errors.length > 0 ? false : null"
                  name="success_story_title"
                  placeholder="Enter Your Success Story Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Success Story Description"
              label-for="success_story_description"
            >
              <validation-provider
                #default="{ errors }"
                name="success_story_description"
              >
                <b-form-textarea
                  id="success_story_description"
                  v-model="form.success_story_description"
                  :state="errors.length > 0 ? false : null"
                  name="success_story_description"
                  placeholder="Enter Your Success Story Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Select Success Story"
              label-for="success_story_id"
            >
              <validation-provider
                #default="{ errors }"
                name="success_story_id"
                rules="required"
              >
                <v-select
                  id="success_story_id"
                  v-model="form.success_story_id"
                  placeholder="Select Success Story"
                  multiple
                  :options="success_stories"
                  label="title"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Special Facility Title"
              label-for="special_facility_title"
            >
              <validation-provider
                #default="{ errors }"
                name="special_facility_title"
                rules="required"
              >
                <b-form-input
                  id="special_facility_title"
                  v-model="form.special_facility_title"
                  :state="errors.length > 0 ? false : null"
                  name="special_facility_title"
                  placeholder="Enter Your Special Facility Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Special Facility Description"
              label-for="special_facility_description"
            >
              <validation-provider
                #default="{ errors }"
                name="special_facility_description"
              >
                <b-form-textarea
                  id="special_facility_description"
                  v-model="form.special_facility_description"
                  :state="errors.length > 0 ? false : null"
                  name="special_facility_description"
                  placeholder="Enter Your Special Facility Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Select Facility" label-for="facility_id">
              <validation-provider
                #default="{ errors }"
                name="facility_id"
                rules="required"
              >
                <v-select
                  id="facility_id"
                  v-model="form.facility_id"
                  placeholder="Select Facility"
                  multiple
                  :options="facilities"
                  label="name"
                  :reduce="(option) => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>

          <div class="col-md-6">
            <b-form-group
              label="Admission Course Title"
              label-for="admission_course_title"
            >
              <validation-provider
                #default="{ errors }"
                name="admission_course_title"
                rules="required"
              >
                <b-form-input
                  id="admission_course_title"
                  v-model="form.admission_course_title"
                  :state="errors.length > 0 ? false : null"
                  name="admission_course_title"
                  placeholder="Enter Your Admission Course Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Admission Course Description"
              label-for="admission_course_description"
            >
              <validation-provider
                #default="{ errors }"
                name="admission_course_description"
              >
                <b-form-textarea
                  id="admission_course_description"
                  v-model="form.admission_course_description"
                  :state="errors.length > 0 ? false : null"
                  name="admission_course_description"
                  placeholder="Enter Your Admission Course Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Seminar Section Title"
              label-for="seminar_title"
            >
              <validation-provider
                #default="{ errors }"
                name="seminar_title"
                rules="required"
              >
                <b-form-input
                  id="seminar_title"
                  v-model="form.seminar_title"
                  :state="errors.length > 0 ? false : null"
                  name="seminar_title"
                  placeholder="Enter Your Seminar Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Seminar Section Description"
              label-for="seminar_description"
            >
              <validation-provider
                #default="{ errors }"
                name="seminar_description"
              >
                <b-form-textarea
                  id="seminar_description"
                  v-model="form.seminar_description"
                  :state="errors.length > 0 ? false : null"
                  name="seminar_description"
                  placeholder="Enter Your Seminar Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-img
              :src="seminar_image"
              height="200"
              width="252"
              class="rounded mr-2 mb-1 mb-md-0 d-inline-block"
            />

            <b-form-group
              class="d-inline-block mt-2"
              label="Seminar Section Image"
              label-for="seminar_image"
            >
              <b-form-file
                id="seminar_image"
                accept=".jpg, .png, .gif"
                @change="loadImagePage($event)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
              label="Partners Section Title"
              label-for="partners_title"
            >
              <validation-provider
                #default="{ errors }"
                name="partners_title"
                rules="required"
              >
                <b-form-input
                  id="partners_title"
                  v-model="form.partners_title"
                  :state="errors.length > 0 ? false : null"
                  name="partners_title"
                  placeholder="Enter Your Partners Section Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Partners Section Description"
              label-for="partners_description"
            >
              <validation-provider
                #default="{ errors }"
                name="partners_description"
              >
                <b-form-textarea
                  id="partners_description"
                  v-model="form.partners_description"
                  :state="errors.length > 0 ? false : null"
                  name="partners_description"
                  placeholder="Enter Your Partners Section Description"
                  rows="4"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group label="Meta Title" label-for="meta_title">
              <validation-provider
                #default="{ errors }"
                name="meta_title"
                rules="required"
              >
                <b-form-input
                  id="meta_title"
                  v-model="form.meta_title"
                  :state="errors.length > 0 ? false : null"
                  name="meta_title"
                  placeholder="Enter Your Meta Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Meta Description" label-for="meta_description">
              <validation-provider
                #default="{ errors }"
                name="meta_description"
                rules="required"
              >
                <b-form-textarea
                  id="meta_description"
                  v-model="form.meta_description"
                  :state="errors.length > 0 ? false : null"
                  name="meta_description"
                  placeholder="Enter Your Meta Description"
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <!-- submit buttons -->
        <b-button type="submit" variant="primary" @click="validationForm">
          Update
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
export default {
  data() {
    return {
      form: {
        favorite_course_title: "",
        favorite_course_description: "",
        success_story_title: "",
        success_story_description: "",
        success_story_id: [],
        facility_id: [],
        special_facility_title: "",
        special_facility_description: "",
        comment_title: "",
        comment_description: "",
        admission_course_title: "",
        admission_course_description: "",
        seminar_title: "",
        seminar_description: "",
        seminar_image: null,
        partners_title: "",
        partners_description: "",
        meta_title: "",
        meta_description: "",
      },
      seminar_image: this.$store.state.base_url + "images/course/default.png",
      required,
      email,
    };
  },
  mounted() {
    this.$store.dispatch("getSuccessStory");
    this.$store.dispatch("getFacilities");
    this.getHome();
  },
  methods: {
    async getHome() {
      const home = await this.callApi("get", "/app/home/show");
      if (home.status == 200) {
        this.form.favorite_course_title = home.data.home.favorite_course_title;
        this.form.favorite_course_description =
          home.data.home.favorite_course_description;
        this.form.success_story_title = home.data.home.success_story_title;
        this.form.success_story_description =
          home.data.home.success_story_description;
        this.form.special_facility_title =
          home.data.home.special_facility_title;
        this.form.special_facility_description =
          home.data.home.special_facility_description;
        this.form.comment_title = home.data.home.comment_title;
        this.form.comment_description = home.data.home.comment_description;
        this.form.admission_course_title =
          home.data.home.admission_course_title;
        this.form.admission_course_description =
          home.data.home.admission_course_description;
        this.form.seminar_title = home.data.home.seminar_title;
        this.form.seminar_description = home.data.home.seminar_description;
        this.form.partners_title = home.data.home.partners_title;
        this.form.partners_description = home.data.home.partners_description;
        this.form.meta_title = home.data.home.meta_title;
        this.form.meta_description = home.data.home.meta_description;
        this.form.success_story_id = [];
        this.form.facility_id = [];
        home.data.home.success_stories.forEach((success_story) => {
          this.form.success_story_id.push(success_story.id);
        });
        home.data.home.facilities.forEach((facility) => {
          this.form.facility_id.push(facility.id);
        });
        this.seminar_image =
          this.$store.state.base_url +
          (home.data.home.seminar_image
            ? home.data.home.seminar_image
            : "images/home/seminar_default.jpg");
      }
    },
    loadImagePage(e) {
      this.form.seminar_image = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.seminar_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    Update() {
      const fd = new FormData();
      if (this.form.seminar_image) {
        fd.append(
          "seminar_image",
          this.form.seminar_image,
          this.form.seminar_image.name
        );
      }
      fd.append("favorite_course_title", this.form.favorite_course_title);
      fd.append(
        "favorite_course_description",
        this.form.favorite_course_description
      );
      fd.append("success_story_title", this.form.success_story_title);
      fd.append(
        "success_story_description",
        this.form.success_story_description
      );
      fd.append("special_facility_title", this.form.special_facility_title);
      fd.append(
        "special_facility_description",
        this.form.special_facility_description
      );
      fd.append("comment_title", this.form.comment_title);
      fd.append("comment_description", this.form.comment_description);
      fd.append("admission_course_title", this.form.admission_course_title);
      fd.append(
        "admission_course_description",
        this.form.admission_course_description
      );
      fd.append("seminar_title", this.form.seminar_title);
      fd.append("seminar_description", this.form.seminar_description);
      fd.append("partners_title", this.form.partners_title);
      fd.append("partners_description", this.form.partners_description);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);

      for (let i = 0; i < this.form.success_story_id.length; i++) {
        let success_story_id = this.form.success_story_id[i];
        fd.append("success_story_id[" + i + "]", success_story_id);
      }

      for (let i = 0; i < this.form.facility_id.length; i++) {
        let facility_id = this.form.facility_id[i];
        fd.append("facility_id[" + i + "]", facility_id);
      }

      axios
        .post("/app/home/update", fd)
        .then((res) => {
          this.getHome();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.favorite_course_title)
              this.e(e.response.data.errors.favorite_course_title[0]);
            if (e.response.data.errors.favorite_course_description)
              this.e(e.response.data.errors.favorite_course_description[0]);
            if (e.response.data.errors.success_story_title)
              this.e(e.response.data.errors.success_story_title[0]);
            if (e.response.data.errors.success_story_description)
              this.e(e.response.data.errors.success_story_description[0]);
            if (e.response.data.errors.special_facility_title)
              this.e(e.response.data.errors.special_facility_title[0]);
            if (e.response.data.errors.special_facility_description)
              this.e(e.response.data.errors.special_facility_description[0]);
            if (e.response.data.errors.comment_title)
              this.e(e.response.data.errors.comment_title[0]);
            if (e.response.data.errors.comment_description)
              this.e(e.response.data.errors.comment_description[0]);
            if (e.response.data.errors.admission_course_title)
              this.e(e.response.data.errors.admission_course_title[0]);
            if (e.response.data.errors.admission_course_description)
              this.e(e.response.data.errors.admission_course_description[0]);
            if (e.response.data.errors.seminar_title)
              this.e(e.response.data.errors.seminar_title[0]);
            if (e.response.data.errors.seminar_description)
              this.e(e.response.data.errors.seminar_description[0]);
            if (e.response.data.errors.seminar_image)
              this.e(e.response.data.errors.seminar_image[0]);
            if (e.response.data.errors.partners_title)
              this.e(e.response.data.errors.partners_title[0]);
            if (e.response.data.errors.partners_description)
              this.e(e.response.data.errors.partners_description[0]);
            if (e.response.data.errors.meta_title)
              this.e(e.response.data.errors.meta_title[0]);
            if (e.response.data.errors.meta_description)
              this.e(e.response.data.errors.meta_description[0]);
            if (e.response.data.errors.success_story_id)
              this.e(e.response.data.errors.success_story_id[0]);
          }
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },
  },
  computed: {
    success_stories() {
      return this.$store.getters.getSuccessStory;
    },
    facilities() {
      return this.$store.getters.getFacilities;
    },
  },
  components: {
    vSelect,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>

<style></style>
