<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-card>
      <b-tabs content-class="mt-2" justified>
        <b-tab active title="Page">
          <Page />
        </b-tab>
        <b-tab title="Banner">
          <b-card-text>
            <Banner />
          </b-card-text>
        </b-tab>
        <b-tab title="Featured Text">
          <b-card-text>
            <FeaturedTextOne />
            <FeaturedTextTwo />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
import Page from "./partials/Page.vue";
import Banner from "./partials/Banner.vue";
import FeaturedTextOne from "./partials/FeaturedTextOne.vue";
import FeaturedTextTwo from "./partials/FeaturedTextTwo.vue";
export default {
  components: {
    Banner,
    Page,
    FeaturedTextOne,
    FeaturedTextTwo,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
  },
};
</script>

<style></style>
