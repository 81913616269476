var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border rounded p-2 mb-2"},[_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();}}},[_c('h5',{staticClass:"mb-2"},[_vm._v("Featured Text Two")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Super Title","label-for":"super_title"}},[_c('validation-provider',{attrs:{"name":"super_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"super_title","state":errors.length > 0 ? false : null,"name":"super_title","placeholder":"Enter Your Super Title"},model:{value:(_vm.form.super_title),callback:function ($$v) {_vm.$set(_vm.form, "super_title", $$v)},expression:"form.super_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"title","placeholder":"Enter Your Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"name":"description","placeholder":"Enter Your Description","rows":"4"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('b-form-group',{attrs:{"label":"Button Name 1","label-for":"btn_1"}},[_c('validation-provider',{attrs:{"name":"btn_1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn_1","state":errors.length > 0 ? false : null,"name":"btn_1","placeholder":"Enter Your Button Name"},model:{value:(_vm.form.btn_1),callback:function ($$v) {_vm.$set(_vm.form, "btn_1", $$v)},expression:"form.btn_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-7"},[_c('b-form-group',{attrs:{"label":"Button Link 1","label-for":"btn_link_1"}},[_c('validation-provider',{attrs:{"name":"btn_link_1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn_link_1","state":errors.length > 0 ? false : null,"name":"btn_link_1","placeholder":"Enter Your Button Link"},model:{value:(_vm.form.btn_link_1),callback:function ($$v) {_vm.$set(_vm.form, "btn_link_1", $$v)},expression:"form.btn_link_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('b-form-group',{attrs:{"label":"Button Name 2","label-for":"btn_1"}},[_c('validation-provider',{attrs:{"name":"btn_2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn_2","state":errors.length > 0 ? false : null,"name":"btn_2","placeholder":"Enter Your Button Name"},model:{value:(_vm.form.btn_2),callback:function ($$v) {_vm.$set(_vm.form, "btn_2", $$v)},expression:"form.btn_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-7"},[_c('b-form-group',{attrs:{"label":"Button Link 2","label-for":"btn_link_2"}},[_c('validation-provider',{attrs:{"name":"btn_link_2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"btn_link_2","state":errors.length > 0 ? false : null,"name":"btn_link_2","placeholder":"Enter Your Button Link"},model:{value:(_vm.form.btn_link_2),callback:function ($$v) {_vm.$set(_vm.form, "btn_link_2", $$v)},expression:"form.btn_link_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])])])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"border rounded p-2 mb-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("Image Setting")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.featured_text_two_image,"height":"174","width":"290"}})],1),_c('b-media-body',[_c('b-form-group',{attrs:{"label":"Image","label-for":"featured_text_two_image"}},[_c('b-form-file',{attrs:{"id":"featured_text_two_image","accept":".jpg, .png, .gif","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.loadImage($event)}}})],1)],1)],1)],1)])]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }